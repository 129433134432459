body {
    margin-bottom: 40px;
}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000000;
}

.error {
    color: #ff0000;
    padding: 10px;
    border: 1px solid #ff0000;
    border-radius: 5px;
    background-color: #ffe6e6;
}

.hidden {
    display: none !important;
}

.release-version {

}

/**
 * notification
 */
.custom-toast {
    padding: 12px 20px;
    border-radius: 4px;
    color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    min-width: 250px;
    max-width: 350px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.toast-message {
    flex-grow: 1;
}
.toast-close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 12px;
    padding: 0 5px;
    opacity: 0.7;
    transition: opacity 0.2s;
    position: absolute;
    top: 0;
    right: 5px;
}
.toast-close-btn:hover {
    opacity: 1;
}
.custom-toast-success {
    background-color: #4caf50;
}
.custom-toast-error {
    background-color: #f44336;
}
.custom-toast-info {
    background-color: #2196f3;
}
.custom-toast-warning {
    background-color: #f3b121;
}
.custom-toast.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

/**
 * Loader
 */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Voile gris transparent */
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Toujours au-dessus du reste */
}
.loader {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/**
 * Modal
 */
/* Modal Styles */
/* Modal Styles */
/* Conteneur principal des modales */
.custom-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
}

/* Overlay de la modale - fond semi-transparent */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1001;
}

.custom-modal-overlay.active {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
}

/* La modale elle-même */
.custom-modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    overflow: hidden;
}

.custom-modal.active {
    opacity: 1;
    transform: translateY(0);
}

/* En-tête de la modale */
.custom-modal-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Titre de la modale */
.custom-modal-title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
}

/* Bouton de fermeture */
.custom-modal-close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.custom-modal-close-btn:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Contenu de la modale */
.custom-modal-content {
    padding: 16px;
    overflow-y: auto;
    flex: 1;
}